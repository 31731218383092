<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- <Alert /> -->
      <!-- Brand logo-->
      <msg-bar />
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <b-link class="brand-logo mt-5">
          <div
            v-if="logoLoading"
            class="spinner-border"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <b-img
            v-if="!logoLoading"
            class="dn-brand-logo"
            variant="light-company"
            :src="companyLogo ? `${bucketUrl}${companyLogo}` : defaultCompanyLogo"
          />
          <h2 class="brand-text text-primary ml-1 mt-1 d-none d-lg-block">
            {{ companyName }}
          </h2>
        </b-link>
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="d-flex justify-content-start align-items-center mb-1">
            <b-img
              v-if="!logoLoading"
              class="dn-brand-logo d-lg-none"
              variant="light-company"
              :src="companyLogo ? `${bucketUrl}${companyLogo}` : defaultCompanyLogo"
            />
            <h2 class="brand-text text-primary ml-1 mt-1 d-lg-none">
              {{ companyName }}
            </h2>
          </div>
          <div class="d-flex justify-content-start align-items-center mb-1">
            <h2 class="font-weight-bold mr-1">
              Welcome to {{ companyName }}</h2>
            <img
              class="d-none d-md-flex"
              style="width:40px;height:40px;"
              src="@/assets/images/gifs/hand-comp.gif"
            >
          </div>
          <b-card-text
            v-if="!is_disable"
            class="mb-2"
          >
            Please sign-in to your account and start the adventure
          </b-card-text>
          <b-card-text
            v-if="is_disable"
            class="mb-2"
            style="font-size: 1.5rem;"
          >
            Please wait a moment, You'll be redirected to your business account shortly!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              v-if="!is_disable"
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                      :required="isRequired"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <br>
                  <small
                    class="text-warning"
                    v-show="(tenant === 'demo' || tenant === 'mng') && sampleEmail"
                  >
                    Demo Email : {{ sampleEmail }}
                  </small>
                  <br>
                  <small
                    v-show="(tenant === 'demo' || tenant === 'mng') && samplePW"
                    class="text-warning"
                  >
                    Demo Password : {{ samplePW }}
                  </small>
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay
                :opacity="0.5"
                :show="loading"
                rounded="sm"
                blur="10px"
              >
                <b-button
                  v-if="!is_disable"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="is_disable"
                  @click="validationForm"
                >
                  Sign in
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
          <div class="mt-3" />
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BImg, BForm, BButton, BOverlay,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { bucketUrl, defaultCompanyLogo, tenant } from '@/constants/config'
import { mapActions } from 'vuex'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import store from '@/store'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
// import Alert from '../components/Notification/Alert.vue'
import MsgBar from '../components/Notification/msg_banner.vue'

const WarehouseDashboardRepository = RepositoryFactory.get('businessSetting')

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    // Alert,
    MsgBar,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessage],
  data() {
    return {
      bucketUrl,
      defaultCompanyLogo,
      status: '',
      password: '',
      token: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.webp'),
      required,
      email,
      loading: false,
      logoLoading: false,
      companyName: '',
      companyLogo: '',
      is_disable: false,
      sampleEmail: process.env.VUE_APP_SAMPLE_EMAIL,
      samplePW: process.env.VUE_APP_SAMPLE_PW,
      tenant,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.webp')
        return this.sideImg
      }
      return this.sideImg
    },
    isRequired() {
      return !this.token
    },
  },
  async created() {
    this.initDataFill()
  },
  async mounted() {
    this.autoLogin()
    await this.readDataforBusiness()
  },
  methods: {
    ...mapActions(['login']),
    async validationForm() {
      const isValid = await this.$refs.loginValidation.validate()
      // eslint-disable-next-line no-underscore-dangle
      this.__clearCache()
      this.loading = true
      try {
        if (isValid) {
          if (this.password) {
            this.payload = {
              email: this.userEmail,
              password: this.password,
            }
          } else if (this.token) {
            this.payload = {
              email: this.userEmail,
              token: this.token,
            }
          } else {
            this.showErrorMessage('Please Enter the Password')
            this.loading = false
            return
          }
          await this.login(this.payload)
        }
      } catch (error) {
        this.convertAndNotifyError(error)
      }
      this.loading = false
    },
    autoLogin() {
      if (this.userEmail && this.password) {
        this.validationForm()
      } else if (this.userEmail && this.token) {
        this.is_disable = true
        this.validationForm()
      }
    },
    async readDataforBusiness() {
      this.logoLoading = true
      try {
        const { data } = (await WarehouseDashboardRepository.getbusinessSettingsForLoginResource()).data
        this.companyName = data.company_name
        this.companyLogo = data.company_logo
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.logoLoading = false
    },
    async initDataFill() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      if (urlSearchParams.get('email') && urlSearchParams.get('password')) {
        this.userEmail = urlSearchParams.get('email')
        this.password = urlSearchParams.get('password')
      } else if (urlSearchParams.get('email') && urlSearchParams.get('token')) {
        this.userEmail = urlSearchParams.get('email')
        this.token = urlSearchParams.get('token')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.dn-brand-logo {
  display: block;
  width: 45px;
  height: 45px;
  margin-right: 0.5rem;
}
@media (max-width: 600px) {
  .brand-text {
    font-size: 16px;
    margin-right: 2rem;
  }

  .font-weight-bold {
    font-size: 15px;
    padding-top: 70px;
  }

  .auth-inner {
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .auth-bg {
    padding: 20px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .auth-login-form {
    width: 100%;
  }

  .brand-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 320px) {
  .auth-inner {
    flex-direction: column;
  }

  .auth-bg {
    height: auto;
  }

  .brand-text {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .font-weight-bold {
    font-size: 1rem;
    padding-top: 1rem;
  }

  .auth-login-form {
    width: 100%;
  }
}
</style>
